import { GET_PAGINATED_PRODUCTS } from "../actionTypes";

export const getPaginatedProducts = (page, limit) => async (dispatch) => {
  try {
    const response = await fetch(
      `https://api.devfrikipolis.com/products/paginate/?page=${page}&limit=${limit}`,
      { credentials: "include" }
    );
    const { success, data } = await response.json();

    if (success) {
      dispatch({
        type: GET_PAGINATED_PRODUCTS,
        payload: data,
      });
    } else {
      console.error("Error fetching paginated products:", data.message);
    }
  } catch (error) {
    console.error("Error fetching paginated products:", error);
  }
};
