import style from "./Detail.module.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import { PrevArrow, NextArrow } from "../../components/Arrows/Arrow";
import { useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { postProductsToEdit, getAllProduct } from "../../redux/actions";
import { useDispatch } from "react-redux";
axios.defaults.withCredentials = true;

const Detail = () => {
  const params = useParams();
  const id = params.id;
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mainPhoto, setMainPhoto] = useState("");
  const [buttonPublicar, setButtonPublicar] = useState(false);
  const [onePic, setOnePic] = useState(false);
  const [newWooPrice, setNewWooPrice] = useState("");
  const productos = useSelector((state) => state.products);
  const allCommerces = useSelector((state) => state.commerces);
  const allCategories = useSelector((state) => state.categories);
  const allSubCategories = useSelector((state) => state.subCategories);
  const [productCategories, setProductCategories] = useState({
    commerceName: "",
    categorieName: "",
    subCategorieName: "",
  });
  const dispatch = useDispatch();
  const editProducts = useSelector((state) => state.editProducts);

  const settings = {
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 500,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  useEffect(() => {
    if (product && product.images) {
      if (product.images.length === 1) {
        setOnePic(true);
      } else {
        setOnePic(false);
      }
    }
  }, [product]);

  useEffect(() => {
    if (product) {
      const { commerceId, categorieId, subCategorieId } = product;

      const commerce = allCommerces.find((c) => c.id === commerceId);
      const category = allCategories.find((c) => c.id === categorieId);
      const subCategory = allSubCategories.find(
        (sc) => sc.id === subCategorieId
      );

      setProductCategories({
        commerceName: commerce ? commerce.name : "",
        categorieName: category ? category.name : "",
        subCategorieName: subCategory ? subCategory.name : "",
      });
    }
  }, [product, allCommerces, allCategories, allSubCategories]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiData = await axios.get(
          `https://api.devfrikipolis.com/products/${id}`
        );
        const data = apiData.data.data;

        setProduct(data);
        setMainPhoto(data.images[0].src);
        setLoading(false);
      } catch (error) {
        alert(error.message);
      }
    };

    fetchData();
  }, [id, productos]);

  const isProductInEdit = editProducts.some((p) => p.id === product?.id);

  const filterProductData = (product) => {
    const uniqueImages = product.images
      .filter((image) => image.src !== "")
      .map((image) => image.src)
      .filter((value, index, self) => self.indexOf(value) === index)
      .slice(0, 2)
      .map((src) => ({ src }));

    const formattedName = product.name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    const quantityMatch = product.name.match(/(\d+)\s*\+\s*(\d+)/);
    let totalQuantity = 1;

    if (quantityMatch) {
      const [baseQuantity, extraQuantity] = quantityMatch
        .slice(1, 3)
        .map(Number);
      totalQuantity = baseQuantity + extraQuantity;
    }

    if (product.origin_price == 0) {
      return null;
    }

    const regularPrice = product.origin_price * totalQuantity;
    const regularPriceString = regularPrice.toString();

    return {
      name: formattedName,
      type: product.type,
      regular_price: regularPriceString,
      status: product.status,
      catalog_visibility: product.catalog_visibility,
      description: product.description,
      short_description: product.short_description,
      categories: product.categories,
      images: uniqueImages,
      stock_status: product.stock_status,
    };
  };

  const publishProduct = async () => {
    try {
      setLoading(true);

      const filteredProduct = filterProductData(product);

      if (filteredProduct !== null) {
        const response = await axios.post(
          `https://api.devfrikipolis.com/woo/products/create/${id}`,
          filteredProduct
        );

        if (response.data.success === true) {
          dispatch(getAllProduct());
          setButtonPublicar(false);
          setLoading(false);
          alert("Publicado en Woo correctamente");
        }
      } else {
        alert("precio del producto es 0");
      }
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };

  const deleteproduct = async () => {
    try {
      setLoading(true);
      const wooId = product.wooId;

      const dataToSend = { wooId: wooId, id: parseInt(id) };
      const response = await axios.post(
        `https://api.devfrikipolis.com/woo/products/delete/`,
        dataToSend
      );
      dispatch(getAllProduct());

      if (response.data.success === true) {
        setLoading(false);
        alert("Eliminado de Woo correctamente");
      } else {
        setLoading(false);
      }
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  const newPriceFunction = (e) => {
    const newPrice = e.target.value;
    setNewWooPrice(newPrice);
  };

  const editWooPrice = async () => {
    const body = { regular_price: newWooPrice };
    setLoading(true);
    try {
      const response = await axios.put(
        `https://api.devfrikipolis.com/woo/products/update/${product.wooId}`,
        body
      );

      if (response.data.success === true) {
        alert("Precio editado correctamente");
      }
    } catch (error) {
      alert("Error al editar el precio: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={style.divContenedorDetail}>
      {loading ? (
        <Spinner />
      ) : product ? (
        <>
          <div className={style.divDetail}>
            <div className={style.divImages}>
              <div className={style.divMainImg}>
                <img src={mainPhoto != "" ? mainPhoto : ""} alt="mainPhoto" />
                <div
                  className={
                    !product.publish ? style.notPublish : style.publish
                  }
                >
                  <h4>
                    {product.extraInfo === ""
                      ? "Disponible"
                      : product.extraInfo}
                  </h4>
                  <h3>{!product.publish ? "Sin Publicar" : "Publicado"}</h3>
                  <h5>
                    {product.conditionals !== null ? product.conditionals : ""}
                  </h5>
                </div>
              </div>
              <div className={style.carouselContainer}>
                {!onePic ? (
                  <Slider {...settings}>
                    {product.images.map((img, index) => (
                      <div key={index}>
                        <div
                          className={style.divContainerImgCar}
                          onClick={() => setMainPhoto(img.src)}
                        >
                          <img src={img.src} alt="image" />
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={style.prodInfo}>
              <h1>
                {product.name.charAt(0).toUpperCase() + product.name.slice(1)}
              </h1>
              <div className={style.divCategories}>
                <p>{productCategories.commerceName}</p>
                <p>{productCategories.categorieName}</p>
                <p>{productCategories.subCategorieName}</p>
              </div>
              <div>
                <h5>€ {product.origin_price}</h5>
                <h2>€ {product.regular_price}</h2>
              </div>
              <div
                className={style.divAddEdicion}
                onClick={() => dispatch(postProductsToEdit(product))}
              >
                <ion-icon
                  name={
                    isProductInEdit
                      ? "checkmark-circle-outline"
                      : "add-circle-outline"
                  }
                ></ion-icon>
                <a href={product.productUrl} target="_blank" rel="link">
                  Visitar Producto
                </a>
              </div>
              <div className={style.divDescriptions}>
                <h3>{product.description}</h3>
                <h4>{product.short_description}</h4>
              </div>
              <div className={style.buttonsDiv}>
                <button
                  className={
                    !product.publish
                      ? style.buttonPublicar
                      : style.buttonQuitarPublicacion
                  }
                  onClick={() => setButtonPublicar(true)}
                >
                  {!product.publish ? "Publicar" : "Quitar publicacion"}
                </button>
                <div
                  className={
                    buttonPublicar ? style.showOptions : style.noOptions
                  }
                >
                  <div
                    className={
                      product.publish
                        ? style.dontShowPublishOptions
                        : style.showPublishOptions
                    }
                  >
                    <button>Editar Precio</button>
                    <button onClick={() => publishProduct()}>
                      Confirmar Publicacion
                    </button>
                    <button onClick={() => setButtonPublicar(false)}>
                      Cancelar
                    </button>
                  </div>
                  <div
                    className={
                      product.publish
                        ? style.showDeleteConfirmation
                        : style.DontshowDeleteConfirmation
                    }
                  >
                    <button onClick={() => deleteproduct()}>Confirmar</button>
                    <button onClick={() => setButtonPublicar(false)}>
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
              {product.publish ? (
                <div className={style.divAccionesWoo}>
                  <h3>Acciones de woo</h3>
                  <div className={style.divContainerInputChangePrice}>
                    <label>Editar precio en Woo</label>
                    <div className={style.divInputChangePrice}>
                      <input
                        type="number"
                        value={newWooPrice}
                        onChange={newPriceFunction}
                        placeholder="Ingrese el nuevo precio"
                      />
                      <button
                        onClick={editWooPrice}
                        disabled={newWooPrice.length < 1}
                      >
                        {loading ? "Cargando..." : "Confirmar"}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        <p>Product not found</p>
      )}
    </div>
  );
};

export default Detail;
