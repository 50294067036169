import style from "./Password.module.css";
import { useState } from "react";
import axios from "axios";
axios.defaults.withCredentials = true;

const PasswordView = () => {
  const [email, setEmail] = useState("");
  const [successMess, setSuccessMess] = useState(false);

  const handleChange = (e) => {
    const newEmail = e.target.value;

    setEmail(newEmail);
  };

  const reqChangePass = async () => {
    try {
      const emailToSend = { email: email };
      const response = await axios.post(
        "https://api.devfrikipolis.com/pass/request-reset",
        emailToSend
      );

      if (response.data.success === true) {
        setSuccessMess(true);
        setEmail("");
      } else {
        alert("error en success");
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className={style.divConteneodrPass}>
      <div className={style.divEmailReset}>
        {successMess ? (
          <div className={style.divSuccesMessage}>
            <h1>Correo enviado Correctamente !</h1>
            <ion-icon name="checkmark-circle-outline"></ion-icon>
            <button onClick={() => setSuccessMess(false)}>Cerrar</button>
          </div>
        ) : (
          ""
        )}
        <h2>Vamos a enviar un mensaje a tu correo electronico</h2>
        <div className={style.divLabelInput}>
          <label>Correo Electronico</label>
          <input
            type="email"
            placeholder="Correo..."
            value={email}
            onChange={handleChange}
          />
          <button onClick={() => reqChangePass()}>
            Solicitar cambio de contraseña
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordView;
