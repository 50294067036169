import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProduct,
  getPaginatedProduct,
  filteredProducts,
  setLoad,
  setFiltersAct,
  setSelections,
  setFilteredLoading,
} from "../../redux/actions";
import Spinner from "../../components/Spinner/Spinner";
import Card from "../../components/Card/Card";
import style from "./Productos.module.css";
import axios from "axios";
axios.defaults.withCredentials = true;

const Productos = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories);
  const subCategories = useSelector((state) => state.subCategories);
  const commerces = useSelector((state) => state.commerces);
  const paginatedProducts = useSelector((state) => state.paginatedProducts);
  const totalPages = useSelector((state) => state.totalPages);
  const currentPage = useSelector((state) => state.currentPage);
  const filteredProductsState = useSelector((state) => state.filteredProducts);
  const filtered_totalPages = useSelector((state) => state.filtered_totalPages);
  const filtered_currentPage = useSelector(
    (state) => state.filtered_currentPage
  );
  const loading = useSelector((state) => state.loading);
  const activeFilters = useSelector((state) => state.activeFilters);
  const selectedCommerces = useSelector((state) => state.selectedCommerces);
  const selectedSubCategories = useSelector(
    (state) => state.selectedSubCategories
  );
  const selectedCategories = useSelector((state) => state.selectedCategories);
  const selectedPublish = useSelector((state) => state.selectedPublish);
  const selectedState = useSelector((state) => state.selectedState);
  const loadingFiltered = useSelector((state) => state.loadingFiltered);
  const productsPerPage = 15;

  ///////////////////////////////////////////////////
  // commerceId, categorieId, subCategorieId, state, publish
  const [bodyFilters, setBodyFilters] = useState({
    commerceId: selectedCommerces,
    categorieId: selectedCategories,
    subCategorieId: selectedSubCategories,
    state: selectedState,
    publish: selectedPublish,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [displaySubCategories, setdisplaySubCategories] = useState([]);
  const [displaycategories, setDisplaycategories] = useState([]);

  useEffect(() => {
    // Define an async function inside the useEffect hook
    const fetchData = async () => {
      setBodyFilters({
        commerceId: selectedCommerces,
        categorieId: selectedCategories,
        subCategorieId: selectedSubCategories,
        state: selectedState,
        publish: selectedPublish,
      });

      if (selectedCommerces != null) {
        const body = { commerceId: selectedCommerces };

        try {
          const response = await axios.post(
            "https://api.devfrikipolis.com/categories/filter",
            body
          );

          if (response.data.success) {
            setDisplaycategories(response.data.data);
          } else {
            alert("Error fetching categories by commerce");
          }
        } catch (error) {
          console.error("Error fetching categories by commerce", error);
          alert("Error fetching categories by commerce");
        }
      }

      if (selectedCategories != null) {
        const body = { categorieId: selectedCategories };

        const response = await axios.post(
          "https://api.devfrikipolis.com/subcategories/filter",
          body
        );

        if (response.data.success) {
          setdisplaySubCategories(response.data.data);
        } else {
          alert("error fetching subCategories by category");
        }
      }
    };

    fetchData();
  }, [
    selectedCategories,
    selectedCommerces,
    selectedPublish,
    selectedState,
    selectedSubCategories,
  ]);

  // HANDLERS DE FILTROS

  const deleteFiltros = () => {
    dispatch(setSelections(null, "selectedSubCategories"));
    dispatch(setSelections(null, "selectedCategories"));
    dispatch(setSelections(null, "selectedCommerces"));
    dispatch(setSelections(null, "selectedPublish"));
    dispatch(setSelections(null, "selectedState"));
    dispatch(setFiltersAct(false));
    setDisplaycategories([]);
    setdisplaySubCategories([]);
  };

  const handleCommerceClick = async (commerceId) => {
    if (selectedCommerces == commerceId) {
      dispatch(setSelections(null, "selectedCommerces"));
      setDisplaycategories([]);
    } else {
      try {
        dispatch(setSelections(commerceId, "selectedCommerces"));

        const body = { commerceId: commerceId };

        const response = await axios.post(
          "https://api.devfrikipolis.com/categories/filter",
          body
        );

        if (response.data.success) {
          setDisplaycategories(response.data.data);
        } else {
          alert("error fetching categories by commerce");
        }
      } catch (error) {
        alert(error);
      }
    }
  };

  const handleSubCategorieClick = (categorieId) => {
    if (selectedSubCategories == categorieId) {
      dispatch(setSelections(null, "selectedSubCategories"));
    } else {
      dispatch(setSelections(categorieId, "selectedSubCategories"));
    }
  };

  const handleCategoryClick = async (categoryId) => {
    if (selectedCategories == categoryId) {
      dispatch(setSelections(null, "selectedCategories"));
      setdisplaySubCategories([]);
    } else {
      try {
        dispatch(setSelections(categoryId, "selectedCategories"));

        const body = { categorieId: categoryId };

        const response = await axios.post(
          "https://api.devfrikipolis.com/subcategories/filter",
          body
        );

        if (response.data.success) {
          setdisplaySubCategories(response.data.data);
        } else {
          alert("error fetching subCategories by category");
        }
      } catch (error) {
        alert(error);
      }
    }
  };

  const handlePublishClick = (publishOption) => {
    if (selectedPublish == publishOption) {
      dispatch(setSelections(null, "selectedPublish"));
    } else {
      dispatch(setSelections(publishOption, "selectedPublish"));
    }
  };

  const handleStateClick = (stateOption) => {
    if (selectedState == stateOption) {
      dispatch(setSelections(null, "selectedState"));
    } else {
      dispatch(setSelections(stateOption, "selectedState"));
    }
  };

  useEffect(() => {
    dispatch(getPaginatedProduct(currentPage, productsPerPage));
  }, [dispatch]);

  const handlePrevPage = () => {
    const filteredBodyFilters = Object.keys(bodyFilters).reduce((acc, key) => {
      if (bodyFilters[key] !== null) {
        acc[key] = bodyFilters[key];
      }
      return acc;
    }, {});
    if (!activeFilters) {
      if (currentPage > 1) {
        dispatch(getPaginatedProduct(currentPage - 1, productsPerPage));
      }
    } else {
      if (filtered_currentPage > 1) {
        dispatch(
          filteredProducts(
            +filtered_currentPage - 1,
            productsPerPage,
            filteredBodyFilters
          )
        );
      }
    }
  };

  const handleNextPage = () => {
    const filteredBodyFilters = Object.keys(bodyFilters).reduce((acc, key) => {
      if (bodyFilters[key] !== null) {
        acc[key] = bodyFilters[key];
      }
      return acc;
    }, {});
    if (!activeFilters) {
      if (currentPage < totalPages) {
        dispatch(getPaginatedProduct(currentPage + 1, productsPerPage));
      }
    } else {
      if (filtered_currentPage < filtered_totalPages) {
        dispatch(
          filteredProducts(
            +filtered_currentPage + 1,
            productsPerPage,
            filteredBodyFilters
          )
        );
      }
    }
  };

  const handleFirstPage = () => {
    const filteredBodyFilters = Object.keys(bodyFilters).reduce((acc, key) => {
      if (bodyFilters[key] !== null) {
        acc[key] = bodyFilters[key];
      }
      return acc;
    }, {});
    if (!activeFilters) {
      dispatch(getPaginatedProduct(1, productsPerPage));
    } else {
      dispatch(filteredProducts(1, productsPerPage, filteredBodyFilters));
    }
  };

  const handleLastPage = () => {
    const filteredBodyFilters = Object.keys(bodyFilters).reduce((acc, key) => {
      if (bodyFilters[key] !== null) {
        acc[key] = bodyFilters[key];
      }
      return acc;
    }, {});
    if (!activeFilters) {
      dispatch(getPaginatedProduct(totalPages, productsPerPage));
    } else {
      dispatch(
        filteredProducts(
          filtered_totalPages,
          productsPerPage,
          filteredBodyFilters
        )
      );
    }
  };

  const getFilteredProds = () => {
    const filteredBodyFilters = Object.keys(bodyFilters).reduce((acc, key) => {
      if (bodyFilters[key] !== null) {
        acc[key] = bodyFilters[key];
      }
      return acc;
    }, {});

    dispatch(setFiltersAct(true));
    dispatch(setFilteredLoading(true));

    dispatch(filteredProducts(1, productsPerPage, filteredBodyFilters));
  };

  const updateProducts = async () => {
    try {
      const response = await axios.get(
        "https://api.devfrikipolis.com/process/getAllTask"
      );

      if (response.data.success) {
        alert("actualizados correctamente");
      } else {
        alert("error actualizando");
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    dispatch(getAllProduct);
  }, []);

  return (
    <div className={style.divContenedorProductos}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className={style.divFiltros}>
            <h1>Filtros y Busqueda</h1>
            <div className={style.divComercios}>
              <h2> Por Comercios:</h2>
              {commerces.map((c) => (
                <div className={style.divComercio} key={c.id}>
                  <button
                    onClick={() => handleCommerceClick(c.id)}
                    className={selectedCommerces == c.id ? style.active : ""}
                  >
                    {c.name}
                  </button>
                </div>
              ))}
            </div>
            <h2> Por Categorias:</h2>
            <div className={style.divCategorias}>
              {displaycategories.map((c) => (
                <div className={style.divCategoria} key={c.id}>
                  <button
                    onClick={() => handleCategoryClick(c.id)}
                    className={selectedCategories == c.id ? style.active : ""}
                  >
                    {c.name}
                  </button>
                </div>
              ))}
            </div>
            <h2>Por SubCategorias:</h2>
            <div className={style.divCategorias}>
              {displaySubCategories.map((c) => (
                <div className={style.divCategoria} key={c.id}>
                  <button
                    className={
                      selectedSubCategories === c.id ? style.active : ""
                    }
                    onClick={() => handleSubCategorieClick(c.id)}
                  >
                    {c.name}
                  </button>
                </div>
              ))}
            </div>
            <div className={style.divPublish}>
              <h2>Por publicado:</h2>
              <div className={style.Publish}>
                <button
                  onClick={() => handlePublishClick(true)}
                  className={selectedPublish ? style.active : ""}
                >
                  Publicado
                </button>
                <button
                  onClick={() => handlePublishClick(false)}
                  className={selectedPublish === false ? style.active : ""}
                >
                  No Publicado
                </button>
              </div>
            </div>
            <div className={style.divState}>
              <h2>Por Estado:</h2>
              <div className={style.state}>
                <button
                  onClick={() => handleStateClick(true)}
                  className={selectedState ? style.active : ""}
                >
                  Activo
                </button>
                <button
                  onClick={() => handleStateClick(false)}
                  className={selectedState === false ? style.active : ""}
                >
                  Inactivo
                </button>
              </div>
            </div>
            <div className={style.buttonsFiltros}>
              <button onClick={() => getFilteredProds()}>
                Aplicar Filtros
              </button>
              <button onClick={() => deleteFiltros()}>Quitar Filtros</button>
            </div>
          </div>
          <div className={style.paginadoYproductos}>
            <div className={style.pagination}>
              <button onClick={handleFirstPage}>Primera</button>
              <button onClick={handlePrevPage}>Anterior</button>
              {activeFilters ? (
                <span>
                  Página {filtered_currentPage} de {filtered_totalPages}
                </span>
              ) : (
                <span>
                  Página {currentPage} de {totalPages}
                </span>
              )}
              <button onClick={handleNextPage}>Siguiente</button>
              <button onClick={handleLastPage}>Última</button>
            </div>
            {activeFilters ? (
              loadingFiltered ? (
                <Spinner />
              ) : (
                <div className={style.divProductos}>
                  {filteredProductsState.length > 0 ? (
                    filteredProductsState.map((p) => <Card key={p.id} {...p} />)
                  ) : (
                    <h1>No hay productos...</h1>
                  )}
                </div>
              )
            ) : (
              <div className={style.divProductos}>
                {paginatedProducts.length > 0 ? (
                  paginatedProducts.map((p) => <Card key={p.id} {...p} />)
                ) : (
                  <Spinner />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Productos;
