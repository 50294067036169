import { GET_FILTERED_PRODUCTS } from "../actionTypes";
import axios from "axios";
axios.defaults.withCredentials = true;

export const getFilteredProducts =
  (page, limit, filters) => async (dispatch) => {
    try {
      const response = await axios.post(
        `https://api.devfrikipolis.com/products/filter/?page=${page}&limit=${limit}`,
        filters
      );

      const products = response.data.data.data;

      if (response.data.success) {
        dispatch({
          type: GET_FILTERED_PRODUCTS,
          payload: { ...response.data.data, products: products },
        });
      } else {
        console.error("Error fetching filtered products:");
      }
    } catch (error) {
      console.error("Error fetching filtered products:", error);
    }
  };
