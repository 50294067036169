// Importaciones de views y componentes

import Login from "./views/Login/Login";
import Home from "./views/Home/Home";
import NavBar from "./components/NavBar/NavBar";
import Detail from "./views/Detail_page/Detail";
import Productos from "./views/Productos/Productos";
import Secciones from "./views/Secciones/Secciones";
import ProductosNoDisponibles from "./views/ProductosNoDisponibles/ProductosNoDisponibles";
import EdicionMasiva from "./views/EdicionMasiva/EdicionMasiva";
import Dashboard from "./views/dashboard/Dashboard";
import PasswordView from "./views/password/Password";
import ChangePassword from "./views/password/ChangePassword";
import Soporte from "./components/Soporte/Soporte";
import {
  getAllProduct,
  getCategories,
  getCommerces,
  getSubCategories,
  getAllUsers,
} from "./redux/actions";

//importaciones de utiles
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//Importacion de css
import "./App.css";
import { useEffect } from "react";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productos = useSelector((state) => state.products);
  const userId = localStorage.getItem("idUser") || "default-user-id";

  useEffect(() => {
    if (userId && userId !== 'default-user-id') {
      dispatch(getAllProduct());
      dispatch(getCategories());
      dispatch(getCommerces());
      dispatch(getSubCategories());
      dispatch(getAllUsers());
    }
  }, [userId]);

  const ProtectedRoute = ({ children }) => {
    const userId = localStorage.getItem("idUser");

    if (!userId) {
      return <Navigate to="/" />;
    }

    return children;
  };

  const checkSessionValidity = () => {
    const loginTime = localStorage.getItem("loginTime");
    if (!loginTime) {
      return;
    }

    const now = Date.now();
    const timePassed = now - parseInt(loginTime);

    if (timePassed > 24 * 60 * 60 * 1000) {
      localStorage.removeItem("idUser");
      localStorage.removeItem("loginTime");
      navigate("/");
    }
  };

  useEffect(() => {
    checkSessionValidity();
  }, []);

  return (
    <>
      {location.pathname !== "/" &&
        !location.pathname.startsWith("/changePass") &&
        !location.pathname.startsWith("/reset/") && <NavBar />}
      {location.pathname !== "/" && <Soporte />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/productos"
          element={
            <ProtectedRoute>
              <Productos />
            </ProtectedRoute>
          }
        />
        <Route
          path="/detail/:id"
          element={
            <ProtectedRoute>
              <Detail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/secciones"
          element={
            <ProtectedRoute>
              <Secciones />
            </ProtectedRoute>
          }
        />
        <Route
          path="/productosNoDisponibles"
          element={
            <ProtectedRoute>
              <ProductosNoDisponibles />
            </ProtectedRoute>
          }
        />
        <Route
          path="/accionesMasivas"
          element={
            <ProtectedRoute>
              <EdicionMasiva />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/changePass" element={<PasswordView />} />
        <Route path="/reset/:token" element={<ChangePassword />} />
      </Routes>
    </>
  );
}

export default App;
