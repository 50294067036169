import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./CrearCategoria.module.css";
import { getCategories } from "../../../redux/actions";
import axios from "axios";
axios.defaults.withCredentials = true;

const CrearCategoria = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCommerce, setSelectedCommerce] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [image, setImage] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const dispatch = useDispatch();
  const [editingCategorieId, setEditingCategorieId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    name: "",
    state: "",
    image: "",
  });

  const categories = useSelector((state) => state.categories);
  const commerces = useSelector((state) => state.commerces);

  const handleSelectCommerce = (commerce) => {
    setSelectedCommerce(commerce);
    setSearchTerm(commerce.name);
    setShowOptions(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage("https://www.ejemplo.com/url-de-la-imagen-subida.png");
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async () => {
    const payload = {
      commerceId: selectedCommerce?.id,
      name: categoryName,
      image: [{ src: image }],
    };

    try {
      const response = await axios.post(
        "https://api.devfrikipolis.com/categories/create",
        payload
      );

      if (response.data.success === true) {
        alert("creado correctamente");
        setSelectedCommerce(null);
        setSearchTerm("")
        setCategoryName("");
        setImage("");
        dispatch(getCategories());
      } else {
        alert("no se pudo crear");
      }
    } catch (error) {
      alert(error)
    }
  };

  const editCategorieHandler = async (id) => {
    const updatedCategory = {
      name: editFormData.name,
      state: editFormData.state,
      image: [{ src: editFormData.image }],
    };
    try {
      setEditingCategorieId(null);
      const response = await axios.patch(
        `https://api.devfrikipolis.com/categories/update/${id}`,
        updatedCategory
      );

      if (response.data.success == true) {
        dispatch(getCategories());
        alert("editado correctamente");
        setEditFormData({
          name: "",
          state: "",
          image: "",
        });
      } else {
        alert("no se pudo editar");
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleEditFormChange = (e) => {
    setEditFormData({
      ...editFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCancelEdit = () => {
    setEditingCategorieId(null);
    setEditFormData({
      name: "",
      state: "",
      image: "",
    });
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <h1>Crear Categoria</h1>
        <input
          className={styles.inputBuscar}
          type="text"
          placeholder="Buscar comercio"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setShowOptions(e.target.value !== "");
          }}
        />
        {showOptions && (
          <div className={styles.commerceOptions}>
            {commerces
              .filter((commerce) =>
                commerce.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((commerce) => (
                <div
                  key={commerce.id}
                  onClick={() => handleSelectCommerce(commerce)}
                  className={styles.commerceOption}
                >
                  {commerce.name}
                </div>
              ))}
          </div>
        )}
        <input
          className={styles.inputCategoria}
          type="text"
          placeholder="Nombre de la categoría"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />
        <input type="file" onChange={handleImageChange} />
        <button className={styles.buttonSubmit} onClick={handleSubmit}>
          Crear Categoría
        </button>
      </div>
      <div className={styles.comerciosActivosContainer}>
        <h2>Categorías Activas:</h2>
        {categories.map((category) => (
          <div key={category.id} className={styles.comercioActivo}>
            {editingCategorieId === category.id ? (
              <div className={styles.optionsToEdit}>
                <input
                  type="text"
                  name="name"
                  placeholder="Nombre"
                  defaultValue={category.name}
                  onChange={handleEditFormChange}
                />
                <label>Estado</label>
                <select
                  name="state"
                  defaultValue={category.state.toString()}
                  onChange={handleEditFormChange}
                >
                  <option value="true">Activo</option>
                  <option value="false">Inactivo</option>
                </select>
                <input
                  type="text"
                  name="image"
                  placeholder="Imagen URL"
                  defaultValue={category.image[0]?.src || ""}
                  onChange={handleEditFormChange}
                />
                <button onClick={() => editCategorieHandler(category.id)}>
                  Guardar
                </button>
                <button onClick={handleCancelEdit}>Cancelar</button>
              </div>
            ) : (
              <>
                <h3>{category.name}</h3>
                <button
                  onClick={() => {
                    setEditingCategorieId(category.id);
                    setEditFormData({
                      name: category.name,
                      state: category.state.toString(),
                      image: category.image[0]?.src || "",
                    });
                  }}
                >
                  Editar
                </button>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CrearCategoria;
