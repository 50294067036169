import React from "react";

const PrevArrow = (props) => {
  return (
    <div
      className={props.className}
      style={{ ...props.style, background: "green", borderRadius: "50%" }}
      onClick={props.onClick}
    />
  );
};

const NextArrow = (props) => {
  return (
    <div
      className={props.className}
      style={{ ...props.style, background: "green", borderRadius: "50%" }}
      onClick={props.onClick}
    />
  );
};

export { PrevArrow, NextArrow };
