import React, { useEffect, useState } from "react";
import style from "./CrearUsuario.module.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../redux/actions";
axios.defaults.withCredentials = true;

const CrearUsuarios = () => {
  const [email, setEmail] = useState("");
  const [editandoId, setEditandoId] = useState(null);
  const [emailEditado, setEmailEditado] = useState("");
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const agregarUsuario = async (e) => {
    e.preventDefault();
    if (!email) return;
    const usuario = { email };
    try {
      const nuevoUsuario = await axios.post(
        "https://api.devfrikipolis.com/users/create",
        usuario
      );
      if (nuevoUsuario.data.success === true) {
        setEmail("");
        dispatch(getAllUsers());
      }
    } catch (error) {
      console.error(error);
    }
  };

  const confirmDelete = (propId) => {
    setShowConfirmMessage(true);
    setDeleteId(propId);
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const eliminarUsuario = async (id) => {
    try {
      await axios.delete(`https://api.devfrikipolis.com/users/delete/${id}`);
      alert("Eliminado correctamente");
      setShowConfirmMessage(false);
      dispatch(getAllUsers());
    } catch (error) {
      console.error("Hubo un error al eliminar el usuario", error);
    }
  };

  return (
    <div
      className={`${style.divContenedorCrearUsuario} ${style.crearRemision}`}
    >
      <h1>Crear Usuarios</h1>
      <form
        onSubmit={agregarUsuario}
        className={style.divContenedorCrearUsuario}
      >
        <input
          type="email"
          placeholder="Correo Electrónico"
          value={editandoId ? emailEditado : email}
          onChange={(e) => setEmail(e.target.value)}
          className={style.divContenedorCrearUsuario}
        />
        <button type="submit" className={style.divGuardarUsuario}>
          {editandoId ? "Guardar Cambios" : "Agregar"}
        </button>
      </form>
      <ul className={style.detalleUsuarios}>
        {users && users.length > 0 ? (
          users.map((usuario) => (
            <li key={usuario.id} className={style.usuarioDetalle}>
              <span>{usuario.email}</span>
              <div className={style.deleteUser}>
                <button
                  onClick={() => confirmDelete(usuario.id)}
                  className={style.divEliminarUsuario}
                >
                  Eliminar
                </button>
                {showConfirmMessage ? (
                  <div className={style.modalBackground}>
                    <div className={style.modalContainer}>
                      <p>Desea eliminar el usuario ?</p>
                      <button onClick={() => eliminarUsuario(deleteId)}>
                        Confirmar
                      </button>
                      <button onClick={() => setShowConfirmMessage(false)}>
                        Cancelar
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </li>
          ))
        ) : (
          <h2>No hay usuarios...</h2>
        )}
      </ul>
    </div>
  );
};

export default CrearUsuarios;
