/* https://api.devfrikipolis.com/commerces */
import { GET_ALL_COMMERCES } from "../actionTypes";
import axios from "axios";
axios.defaults.withCredentials = true;

export const getAllCommerces = () => {
  return async function (dispatch) {
    try {
      const apiData = await axios.get(
        "https://api.devfrikipolis.com/commerces "
      );
      const data = apiData.data.data;

      dispatch({ type: GET_ALL_COMMERCES, payload: data });
    } catch (error) {
      return error.message;
    }
  };
};
