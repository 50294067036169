import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./CrearSubCat.module.css";
import { getCategories, getSubCategories } from "../../../redux/actions";
import axios from "axios";
axios.defaults.withCredentials = true;

const CrearSubCat = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermSub, setSearchTermSub] = useState("");
  const [selectedCommerce, setSelectedCommerce] = useState(null);
  const [selectedSubCategorie, setSelectedSubCategorie] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryWooID, setSubCategoryWooId] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [showOptionsSub, setShowOptionsSub] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [subcategoriesPerPage] = useState(5);
  const dispatch = useDispatch();
  const [editingSubCategorieId, setEditingSubCategorieId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    name: "",
    state: "",
  });

  const categories = useSelector((state) => state.categories);
  const commerces = useSelector((state) => state.commerces);
  const subCategories = useSelector((state) => state.subCategories);

  const handleSelectCommerce = (commerce) => {
    setSelectedCommerce(commerce);
    setSearchTerm(commerce.name);
    setShowOptions(false);
  };

  const handleSelectSubCat = (subCat) => {
    setSelectedSubCategorie(subCat);
    setSearchTermSub(subCat.name);
    setShowOptionsSub(false);
  };

  const handleSubmit = async () => {
    const payload = {
      commerceId: selectedCommerce?.id,
      categorieId: selectedSubCategorie?.id,
      name: subCategoryName,
      wooId: subCategoryWooID,
    };

    try {
      const response = await axios.post(
        "https://api.devfrikipolis.com/subcategories/create",
        payload
      );

      if (response.data.success === true) {
        alert("creado correctamente");
        setSelectedCommerce(null);
        setSelectedSubCategorie(null);
        setSubCategoryWooId("")
        setSearchTerm("");
        setSearchTermSub("");
        setSubCategoryName("");
        dispatch(getSubCategories());
      } else {
        alert("no se pudo crear");
      }
    } catch (error) {
      alert(error);
    }
  };

  const editCategorieHandler = async (id) => {
    const updatedCategory = {
      name: editFormData.name,
      state: editFormData.state,
    };

    try {
      const response = await axios.patch(
        `https://api.devfrikipolis.com/subcategories/update/${id}`,
        updatedCategory
        );

        
        if (response.data.success == true) {
        setEditingSubCategorieId(null);
        dispatch(getSubCategories());
        alert("editado correctamente");
        setEditFormData({
          name: "",
          state: "",
        });
      } else {
        alert("no se pudo editar");
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleEditFormChange = (e) => {
    setEditFormData({
      ...editFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCancelEdit = () => {
    setEditingSubCategorieId(null);
    setEditFormData({
      name: "",
      state: "",
    });
  };

  /* PAGINADO */

  const totalPages = Math.ceil(subCategories.length / subcategoriesPerPage);

  const goToNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const goToPreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  const goToFirstPage = () => setCurrentPage(1);
  const goToLastPage = () => setCurrentPage(totalPages);

  const indexOfLastSubcategory = currentPage * subcategoriesPerPage;
  const indexOfFirstSubcategory = indexOfLastSubcategory - subcategoriesPerPage;
  const currentSubcategories = subCategories.slice(
    indexOfFirstSubcategory,
    indexOfLastSubcategory
  );

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <h1>Crear Subcategoria</h1>
        <input
          className={styles.inputBuscar}
          type="text"
          placeholder="Buscar comercio"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setShowOptions(e.target.value !== "");
          }}
        />
        {showOptions && (
          <div className={styles.commerceOptions}>
            {commerces
              .filter((commerce) =>
                commerce.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((commerce) => (
                <div
                  key={commerce.id}
                  onClick={() => handleSelectCommerce(commerce)}
                  className={styles.commerceOption}
                >
                  {commerce.name}
                </div>
              ))}
          </div>
        )}
        <input
          className={styles.inputBuscar}
          type="text"
          placeholder="Buscar Categoria"
          value={searchTermSub}
          onChange={(e) => {
            setSearchTermSub(e.target.value);
            setShowOptionsSub(e.target.value !== "");
          }}
        />
        {showOptionsSub && (
          <div className={styles.commerceOptions}>
            {categories
              .filter((subCat) =>
                subCat.name.toLowerCase().includes(searchTermSub.toLowerCase())
              )
              .map((subCat) => (
                <div
                  key={subCat.id}
                  onClick={() => handleSelectSubCat(subCat)}
                  className={styles.commerceOption}
                >
                  {subCat.name}
                </div>
              ))}
          </div>
        )}
        <input
          className={styles.inputCategoria}
          type="text"
          placeholder="Nombre de la subcategoria"
          value={subCategoryName}
          onChange={(e) => setSubCategoryName(e.target.value)}
        />
        <input 
          className={styles.inputCategoria}
          type="number"
          placeholder="WooId"
          value={subCategoryWooID}
          onChange={(e) => setSubCategoryWooId(e.target.value)}
        />
        <button className={styles.buttonSubmit} onClick={handleSubmit}>
          Crear Subcategoria
        </button>
      </div>
      <div className={styles.comerciosActivosContainer}>
        <h2>Subcategorias Activas:</h2>
        <div className={styles.pagination}>
          <button onClick={goToFirstPage}>Primera Página</button>
          <button onClick={goToPreviousPage}>Anterior</button>
          <span>
            Página {currentPage} de {totalPages}
          </span>
          <button onClick={goToNextPage}>Siguiente</button>
          <button onClick={goToLastPage}>Última Página</button>
        </div>
        {currentSubcategories.map((category) => (
          <div key={category.id} className={styles.comercioActivo}>
            {editingSubCategorieId === category.id ? (
              <div className={styles.optionsToEdit}>
                <input
                  type="text"
                  name="name"
                  placeholder="Nombre"
                  defaultValue={category.name}
                  onChange={handleEditFormChange}
                />
                <label>Estado</label>
                <select
                  name="state"
                  defaultValue={category.state.toString()}
                  onChange={handleEditFormChange}
                >
                  <option value="true">Activo</option>
                  <option value="false">Inactivo</option>
                </select>
                <button onClick={() => editCategorieHandler(category.id)}>
                  Guardar
                </button>
                <button onClick={handleCancelEdit}>Cancelar</button>
              </div>
            ) : (
              <>
                <h3>{category.name}</h3>
                <button
                  onClick={() => {
                    setEditingSubCategorieId(category.id);
                    setEditFormData({
                      name: category.name,
                      state: category.state.toString(),
                    });
                  }}
                >
                  Editar
                </button>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CrearSubCat;
