import React, { useState } from "react";
import style from "./ProductosNoDisponibles.module.css";
import { useSelector } from "react-redux";
import Card from "../../components/Card/Card";

const ProductosNoDisponibles = () => {
  const productos = useSelector((state) => state.products);
  const productosNoDisponibles = productos.filter(
    (producto) => producto.state === false
  );

  const [paginaActual, setPaginaActual] = useState(1);
  const productosPorPagina = 15;
  const totalPaginas = Math.ceil(
    productosNoDisponibles.length / productosPorPagina
  );

  const indiceUltimoProducto = paginaActual * productosPorPagina;
  const indicePrimerProducto = indiceUltimoProducto - productosPorPagina;
  const productosActuales = productosNoDisponibles.slice(
    indicePrimerProducto,
    indiceUltimoProducto
  );

  const paginate = (pageNumber) => setPaginaActual(pageNumber);

  return (
    <div className={style.ProductosNoDisponiblesDivContenedor}>
      <h1>Productos No Disponibles</h1>
      <div className={style.pagination}>
        <button onClick={() => paginate(1)} disabled={paginaActual === 1}>
          Primera
        </button>
        <button
          onClick={() => paginate(paginaActual - 1)}
          disabled={paginaActual === 1}
        >
          Anterior
        </button>
        <span>pagina {paginaActual} de {totalPaginas}</span>
        <button
          onClick={() => paginate(paginaActual + 1)}
          disabled={paginaActual === totalPaginas}
        >
          Siguiente
        </button>
        <button
          onClick={() => paginate(totalPaginas)}
          disabled={paginaActual === totalPaginas}
        >
          Última
        </button>
      </div>
      <div className={style.cardsNoDispo}>
        {productosActuales.length > 0 ? (
          productosActuales.map((producto) => (
            <Card key={producto.id} {...producto} />
          ))
        ) : (
          <p>No hay productos no disponibles en este momento.</p>
        )}
      </div>
    </div>
  );
};

export default ProductosNoDisponibles;
