import style from "./Password.module.css";
import { useParams, useNavigate } from "react-router";
import { useState } from "react";
import axios from "axios";
axios.defaults.withCredentials = true;

const ChangePassword = () => {
  const params = useParams();
  const navigate = useNavigate();
  const token = params.token;
  const [successMess, setSuccessMess] = useState(false);
  const [seePass, setSeePass] = useState(false);
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleInputPassword = (event) => {
    const newValue = event.target.value;
    if (newValue === "") {
      setPassword(newValue);
      setDisabled(true);
    } else {
      setPassword(newValue);
      setDisabled(false);
    }
  };

  const reqChangePass = async () => {
    try {
      const passToSend = { newPassword: password };
      const response = await axios.post(
        `https://api.devfrikipolis.com/pass/reset/${token}`,
        passToSend
      );

      if (response.data.success === true) {
        setSuccessMess(true);
        setPassword("");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        alert("error en success");
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className={style.divContenedorChangePass}>
      <div className={style.divEmailReset}>
        {successMess ? (
          <div className={style.divSuccesMessage}>
            <h1>Contraseña modificada Correctamente !</h1>
            <ion-icon name="checkmark-circle-outline"></ion-icon>
          </div>
        ) : (
          ""
        )}
        <h2>Ingrese su nueva contraseña</h2>
        <div className={style.divLabelInput}>
          <div className={style.divPassword}>
            <input
              type={seePass ? "text" : "password"}
              placeholder="Contraseña..."
              value={password}
              onChange={handleInputPassword}
            />
            <div onClick={() => setSeePass(!seePass)}>
              <ion-icon name="eye-outline"></ion-icon>
            </div>
          </div>
          <button disabled={disabled} onClick={() => reqChangePass()}>
            Solicitar cambio de contraseña
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
