import style from "./Home.module.css";
import Card from "../../components/Card/Card";
import { useEffect, useState } from "react";
import images from "../../assets/imagenes.png";
import { useSelector } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";

const Home = () => {
  const [productsArr, setProductsArr] = useState([]);
  const [loading, setLoading] = useState(true);
  const productsRedux = useSelector((state) => state.products);

  useEffect(() => {
    const productsSliced = productsRedux.slice(0, 4);

    setProductsArr(productsSliced);
    setLoading(false);
  }, [productsRedux]);

  return (
    <div className={style.divContenedorHome}>
      <h1>Productos Nuevos</h1>
      <h2>Nuevos Productos de WarHammer</h2>
      <div className={style.containerCards}>
        {loading ? (
          <h2>Cargando...</h2>
        ) : productsArr.length > 0 ? (
          productsArr.map((p) => <Card key={p.id} {...p} />)
        ) : (
          <Spinner prop="home" />
        )}
      </div>
    </div>
  );
};

export default Home;
