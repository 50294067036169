import { useState, useEffect, useRef } from "react";
import style from "./NavBar.module.css";
import logo from "../../assets/logo_friki.png";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from "../../redux/actions";

const NavBar = () => {
  const products = useSelector((state) => state.products);
  const [searchValue, setSearchValue] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showLogOut, setShowLogOut] = useState(false);
  const searchRef = useRef(null);
  const perfilNavRef = useRef(null);
  const navigate = useNavigate()

  const userId = localStorage.getItem("idUser") || "default-user-id";
  const [logedUser, setLogedUser] = useState({});
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (users != undefined) {
      const user = users.find((us) => us.id === userId);

      if (user) {
        setLogedUser(user);
      } else {
        setLogedUser({ name: "No Identificado" });
      }
    }

    const handleClickOutside = (event) => {
      if (
        perfilNavRef.current &&
        !perfilNavRef.current.contains(event.target)
      ) {
        setShowLogOut(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [users, userId]);

  const handleLogOut = () => {
    localStorage.removeItem("idUser");

    setShowLogOut(false);
    setLogedUser("");
    navigate("/");
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    if (searchValue.length > 3) {
      const filtered = products.filter((product) =>
        product.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredProducts(filtered);
      setShowSuggestions(true);
    } else {
      setFilteredProducts([]);
      setShowSuggestions(false);
    }
  }, [searchValue, products]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={style.divContenedorNavBar}>
      <img src={logo} alt="logo" />
      <Link to="/home">
        <h3>Inicio</h3>
      </Link>
      <Link to="/productos">
        <h3>Productos</h3>
      </Link>
      <Link to="/secciones">
        <h3>Secciones</h3>
      </Link>
      {logedUser.profile === "superadmin" ? (
        <Link to="/dashboard">
          <h3>Administrar</h3>
        </Link>
      ) : (
        ""
      )}
      <div className={style.divInputSearch} ref={searchRef}>
        <input
          type="text"
          placeholder="Buscar productos..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {showSuggestions && (
          <div className={style.searchResults}>
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product) => (
                <div
                  key={product.id}
                  className={style.divProductoBuscado}
                  onClick={() => setShowSuggestions(false)}
                >
                  <Link to={`detail/${product.id}`}>
                    <img src={product.images[0].src} alt="product" />
                    <h5>{product.name}</h5>
                  </Link>
                </div>
              ))
            ) : (
              <h4>No se encontraron productos !</h4>
            )}
          </div>
        )}
      </div>
      <div className={style.PerfilNav} ref={perfilNavRef}>
        <div
          className={style.divIonIcon}
          onClick={() => setShowLogOut(!showLogOut)}
        >
          <ion-icon name="person-circle-outline"></ion-icon>
          <p>{logedUser.name}</p>
        </div>
        {showLogOut ? (
          <div className={style.divLogOut}>
            <button onClick={() => handleLogOut()}>Cerrar Sesión</button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default NavBar;
