import { Link } from "react-router-dom";
import style from "./Card.module.css";
import { useSelector, useDispatch } from "react-redux";
import { postProductsToEdit } from "../../redux/actions";
import { useEffect } from "react";

const Card = (props) => {
  const {
    id,
    name,
    regular_price,
    images,
    publish,
    short_description,
    extraInfo,
    conditionals,
    state,
  } = props;
  const dispatch = useDispatch();
  const editProducts = useSelector((state) => state.editProducts);

  const isProductInEdit = editProducts.some((p) => p.id === id);
  const isActive = state !== false;

  // Allow editing and showing details if the product has a price and is active
  const canEditAndShowDetail = regular_price > 0 && isActive;

  return (
    <div className={style.containerCard}>
      {canEditAndShowDetail && (
        <div
          className={style.divAddEdicion}
          onClick={() => dispatch(postProductsToEdit(props))}
        >
          <ion-icon
            name={
              isProductInEdit
                ? "checkmark-circle-outline"
                : "add-circle-outline"
            }
          ></ion-icon>
        </div>
      )}
      <Link to={`/detail/${id}`}>
        <div className={style.divContenedorCard}>
          <div className={style.divImgCard}>
            <img src={images[0].src} alt="image" />
          </div>
          <div
            className={
              publish ? style.publishStatusDiv : style.notPublishStatusDiv
            }
          >
            <h4>{publish ? "Publicado" : "Sin Publicar"}</h4>
          </div>
          <h3>{name.charAt(0).toUpperCase() + name.slice(1)}</h3>
          <div className={style.divShortDescription}>
            <h6>{short_description}</h6>
          </div>
          <div className={style.precioDeProducto}>
            <h5>€ {regular_price}</h5>
            {conditionals != null ? <h4>{conditionals}</h4> : ""}
          </div>
          <div className={style.stockDeProducto}>
            <h5>{extraInfo === "" ? "Disponible" : extraInfo}</h5>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Card;
