import style from "./Dashboard.module.css";
import CrearUsuarios from "./crearUsuario/CrearUsuario";
import CrearCategoria from "./crearCategoria/CrearCategoria";
import CrearComercio from "./CrearComercio/CrearComercio";
import CrearSubCat from "./crearSubCat/CrearSubCat";

const Dashboard = () => {
  return (
    <div className={style.containerDashboard}>
      <CrearUsuarios />
      <CrearComercio />
      <CrearCategoria />
      <CrearSubCat />
    </div>
  );
};

export default Dashboard;
