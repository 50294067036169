import style from "./Secciones.module.css";
import { Link } from "react-router-dom";

const Secciones = () => {
  return (
    <div className={style.seccionesDivContenedor}>
      <Link to="/productosNoDisponibles">
        <div>
          <h1>Productos No Disponibles</h1>
        </div>
      </Link>
      <Link to="/accionesMasivas">
        <div>
          <h1>Acciones Masivo</h1>
        </div>
      </Link>
    </div>
  );
};

export default Secciones;
