import {
  GET_ALL_PRODUCTS,
  GET_ALL_CATEGORIES,
  GET_ALL_COMMERCES,
  GET_ALL_SUBCATEGORIES,
  POST_PRODUCTS_TO_EDIT,
  DELETE_PRODUCT_FROM_EDIT,
  CLEAR_EDIT_PRODUCTS,
  GET_ALL_USERS,
  GET_PAGINATED_PRODUCTS,
  CLEAR_ALL_PRODUCTS_TO_EDIT,
  GET_FILTERED_PRODUCTS,
  SET_LOADING,
  SET_FILTERS,
  SET_SELECTIONS,
  SET_LOADING_FILTERED,
} from "./actionTypes";

const initialState = {
  products: [],
  isLoading: true,
  categories: [],
  commerces: [],
  subCategories: [],
  editProducts: [],
  users: [],
  paginatedProducts: [],
  totalItems: 0,
  totalPages: 0,
  currentPage: 1,
  filteredProducts: [],
  filtered_totalItems: 0,
  filtered_totalPages: 0,
  filtered_currentPage: 1,
  loading: true,
  error: null,
  activeFilters: false,
  selectedCommerces: null,
  selectedSubCategories: null,
  selectedCategories: null,
  selectedPublish: null,
  selectedState: null,
  loadingFiltered: true,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
        isLoading: false,
      };
    }

    case SET_SELECTIONS: {
      return {
        ...state,
        [action.payload.select]: action.payload.id,
      };
    }

    case SET_LOADING_FILTERED: {
      return {
        ...state,
        loadingFiltered: action.payload,
      };
    }

    case GET_ALL_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
      };
    }

    case SET_FILTERS: {
      return {
        ...state,
        activeFilters: action.payload,
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case GET_ALL_COMMERCES: {
      return {
        ...state,
        commerces: action.payload,
      };
    }

    case GET_ALL_SUBCATEGORIES: {
      return {
        ...state,
        subCategories: action.payload,
      };
    }

    case POST_PRODUCTS_TO_EDIT: {
      const productExists = state.editProducts.some(
        (product) => product.id === action.payload.id
      );

      if (!productExists) {
        return {
          ...state,
          editProducts: [...state.editProducts, action.payload],
        };
      }
      return state;
    }

    case DELETE_PRODUCT_FROM_EDIT: {
      return {
        ...state,
        editProducts: state.editProducts.filter(
          (product) => product.id !== action.payload
        ),
      };
    }

    case CLEAR_EDIT_PRODUCTS: {
      return {
        ...state,
        editProducts: [],
      };
    }

    case GET_ALL_USERS: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case GET_PAGINATED_PRODUCTS: {
      return {
        ...state,
        paginatedProducts: action.payload.data,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
        loading: false,
      };
    }

    case GET_FILTERED_PRODUCTS:
      return {
        ...state,
        filteredProducts: action.payload.data,
        filtered_totalItems: action.payload.totalItems,
        filtered_totalPages: action.payload.totalPages,
        filtered_currentPage: action.payload.currentPage,
        loadingFiltered: false,
      };

    case CLEAR_ALL_PRODUCTS_TO_EDIT: {
      return {
        ...state,
        editProducts: [],
      };
    }

    default:
      return state;
  }
};

export default rootReducer;
