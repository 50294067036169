export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_COMMERCES = "GET_ALL_COMMERCES";
export const GET_ALL_SUBCATEGORIES = "GET_ALL_SUBCATEGORIES";
export const POST_PRODUCTS_TO_EDIT = "POST_PRODUCTS_TO_EDIT";
export const DELETE_PRODUCT_FROM_EDIT = "DELETE_PRODUCT_FROM_EDIT";
export const CLEAR_EDIT_PRODUCTS = "CLEAR_EDIT_PRODUCTS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_PAGINATED_PRODUCTS = "GET_PAGINATED_PRODUCTS";
export const CLEAR_ALL_PRODUCTS_TO_EDIT = "CLEAR_ALL_PRODUCTS_TO_EDIT";
export const GET_FILTERED_PRODUCTS = "GET_FILTERED_PRODUCTS";
export const SET_LOADING = "SET_LOADING";
export const SET_FILTERS = "SET_FILTERS";
export const SET_SELECTIONS = "SET_SELECTIONS";
export const SET_LOADING_FILTERED = "SET_LOADING_FILTERED";
