import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./EdicionMasiva.module.css";
import {
  deleteProductEdit,
  clearProducts,
  postProductsToEdit,
  getSubCategories,
  clearAllProducts,
  getAllProduct,
} from "../../redux/actions";
import axios from "axios";
import Spinner from "../../components/Spinner/Spinner";
axios.defaults.withCredentials = true;

const EdicionMasiva = () => {
  const [descuento, setDescuento] = useState("");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const editProducts = useSelector((state) => state.editProducts);
  const subCategories = useSelector((state) => state.subCategories);
  const [successMessage, setSuccessMessage] = useState(false);
  const [showDeleteProds, setShowDeleteProds] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [confirmAction, setConfirmAction] = useState(false);
  const [actionType, setActionType] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubCategories());
  }, []);

  useEffect(() => {
    if (editProducts.length > 0) {
      setShowDeleteProds(true);
    } else {
      setShowDeleteProds(false);
    }
  }, [editProducts]);

  const enviarDescuento = async () => {
    const ids = editProducts.map((p) => p.id);
    setSearchTerm("");
    setFilter(false);
    try {
      const response = await fetch(
        "https://api.devfrikipolis.com/products/discount/",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ids, discount: parseInt(descuento, 10) }),
          credentials: "include",
        }
      );
      if (response.ok) {
        setSuccessMessage(true);
      }
    } catch (error) {
      console.error("Error al aplicar el descuento", error);
    }
  };

  const publicarMasivo = async () => {
    setLoading(true);

    try {
      const filteredProducts = editProducts.filter(
        (product) =>
          product.state === true &&
          product.publish === false &&
          product.regular_price &&
          parseFloat(product.regular_price) > 0 &&
          product.name !== "" &&
          !(
            product.extraInfo &&
            product.extraInfo.toLowerCase().includes("no disponible")
          )
      );

      const productsToPublish = filteredProducts.map((product) => {
        const uniqueImages = product.images
          .filter((image) => image.src !== "")
          .map((image) => image.src)
          .filter((value, index, self) => self.indexOf(value) === index)
          .slice(0, 2)
          .map((src) => ({ src }));

        const formattedName = product.name
          .split(" ")
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" ");

        const regularPriceString = product.regular_price.toString();

        return {
          name: formattedName,
          type: product.type,
          regular_price: regularPriceString,
          status: product.status,
          catalog_visibility: product.catalog_visibility,
          description: product.description,
          short_description: product.short_description,
          categories: product.categories,
          images: uniqueImages,
          stock_status: product.stock_status,
        };
      });

      const chunkArray = (array, chunkSize) =>
        Array.from({ length: Math.ceil(array.length / chunkSize) }, (v, i) =>
          array.slice(i * chunkSize, i * chunkSize + chunkSize)
        );

      const productChunks = chunkArray(productsToPublish, 60);

      for (const chunk of productChunks) {
        const requestBody = { create: chunk };
        const response = await axios.post(
          "https://api.devfrikipolis.com/woo/products/batch/create",
          requestBody
        );

        if (response.data.success !== true) {
          alert("Error en una de las peticiones");
        }
      }

      setLoading(false);
      alert(`Publicados correctamente (${productsToPublish.length}) productos`);
      dispatch(clearAllProducts());
      dispatch(getAllProduct());
    } catch (error) {
      console.error("Error al publicar productos", error);
      setLoading(false);
    }
  };

  const deleteMasivo = async () => {
    setLoading(true);

    try {
      const filteredProducts = editProducts.filter(
        (product) => product.publish === true
      );

      const productsToDelete = filteredProducts.map((product) => product.wooId);

      const chunkArray = (array, chunkSize) =>
        Array.from({ length: Math.ceil(array.length / chunkSize) }, (v, i) =>
          array.slice(i * chunkSize, i * chunkSize + chunkSize)
        );

      const productChunks = chunkArray(productsToDelete, 60);

      for (const chunk of productChunks) {
        const requestBody = { delete: chunk };
        const response = await axios.post(
          "https://api.devfrikipolis.com/woo/products/batch/delete",
          requestBody
        );

        if (response.data.success !== true) {
          alert("Ocurrió un error al eliminar algunos productos");
        }
      }

      setLoading(false);
      alert(`Productos eliminados correctamente (${productsToDelete.length})`);
      dispatch(clearAllProducts());
      dispatch(getAllProduct());
    } catch (error) {
      console.error("Error al eliminar productos", error);
      setLoading(false);
      alert("Error al eliminar productos: " + error.message);
    }
  };

  const closeSuccessMes = () => {
    dispatch(clearProducts());
    setSuccessMessage(false);
    setDescuento("");
  };

  const filteredSubCategories = subCategories.filter((subCategory) =>
    subCategory.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchAllProducts = async (subCategoryId, name) => {
    try {
      setSearchTerm(name);
      setFilter(false);
      setLoadingProducts(true);
      let allProducts = [];
      let currentPage = 1;
      let totalPages = 0;

      do {
        const response = await axios.post(
          `https://api.devfrikipolis.com/products/filter?page=${currentPage}&limit=10`,
          { subCategorieId: subCategoryId }
        );

        if (response.data.success) {
          const data = response.data.data;

          allProducts = allProducts.concat(data.data);
          totalPages = data.totalPages;
          currentPage++;
        } else {
          console.error(
            "Error al obtener los productos:",
            response.data.message
          );
          break;
        }
      } while (currentPage <= totalPages);
      allProducts.forEach((product) => {
        dispatch(postProductsToEdit(product));
      });
    } catch (error) {
      console.error("Error al cargar productos de la subcategoría", error);
    } finally {
      setLoadingProducts(false);
    }
  };

  const handleClearAllProducts = () => {
    setSearchTerm("");
    setFilter(false);
    dispatch(clearAllProducts());
  };

  const handleDropDown = (e) => {
    const search = e.target.value;

    setSearchTerm(search);

    if (search.length > 0) {
      setFilter(true);
    } else {
      setFilter(false);
    }
  };

  const handleConfirm = (action) => {
    setActionType(action);
    setConfirmAction(true);
  };

  const handleAction = async () => {
    setConfirmAction(false);
    switch (actionType) {
      case "descuento":
        await enviarDescuento();
        break;
      case "publicar":
        await publicarMasivo();
        break;
      case "eliminar":
        await deleteMasivo();
        break;
      default:
        break;
    }
  };

  return (
    <div className={style.divContenedorEdicionMas}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {confirmAction ? (
            <div className={style.confirmActionDiv}>
              <h2>Confirmar accion</h2>
              <div>
                <button onClick={handleAction}>Confirmar</button>
                <button onClick={() => setConfirmAction(false)}>
                  Cancelar
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          <h1>Edición Masiva</h1>
          <div className={style.divContSearch}>
            <input
              type="text"
              placeholder="Buscar Subcategoría..."
              value={searchTerm}
              onChange={(e) => handleDropDown(e)}
              className={style.input}
            />
            {filter && (
              <div className={style.dropdown}>
                {filteredSubCategories.map((subCategory) => (
                  <div
                    key={subCategory.id}
                    onClick={() =>
                      fetchAllProducts(subCategory.id, subCategory.name)
                    }
                    className={style.dropdownItem}
                  >
                    {subCategory.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          {showDeleteProds ? (
            <button onClick={handleClearAllProducts} className={style.button}>
              Limpiar Selección
            </button>
          ) : (
            ""
          )}
          <div className={style.contenedorProductos}>
            {loadingProducts ? (
              <Spinner />
            ) : editProducts.length > 0 ? (
              editProducts.map((producto) => (
                <div key={producto.id} className={style.productoSeleccionado}>
                  <h4>{producto.name}</h4>
                  <img src={producto.images[0].src} alt="producto" />
                  <h4>${producto.origin_price}</h4>
                  <button
                    onClick={() => dispatch(deleteProductEdit(producto.id))}
                    className={style.eliminarBtn}
                  >
                    Limpiar
                  </button>
                </div>
              ))
            ) : (
              <h2>No hay productos seleccionados</h2>
            )}
          </div>
          <div className={style.divAccionesMasivas}>
            <div className={style.divDescuentoMas}>
              <h3>Descuento Masivo</h3>
              <input
                type="number"
                placeholder="Descuento (%)"
                value={descuento}
                onChange={(e) => setDescuento(e.target.value)}
                className={style.input}
              />
              <button
                onClick={() => handleConfirm("descuento")}
                className={style.button}
                disabled={descuento.length < 1}
              >
                Aplicar Descuento
              </button>
            </div>
            <div className={style.divPublicarMas}>
              <h3>Publicar Masivamente</h3>
              <button onClick={() => handleConfirm("publicar")}>
                Publicar Seleccionados
              </button>
            </div>
            <div className={style.divEliminarMas}>
              <h3>Eliminar Masivamente</h3>
              <button onClick={() => handleConfirm("eliminar")}>
                Eliminar Seleccionados
              </button>
            </div>
          </div>
          {successMessage ? (
            <div className={style.divSuccesMessage}>
              <h1>{`Productos editados con ${descuento}% de descuento !`}</h1>
              <ion-icon name="checkmark-circle-outline"></ion-icon>
              <button onClick={() => closeSuccessMes()}>Cerrar</button>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default EdicionMasiva;
