import style from "./Soporte.module.css";
import { Link } from "react-router-dom";

const Soporte = () => {
  return (
    <div className={style.divSoporte}>
      <Link
        target="_blank"
        to={`https://api.whatsapp.com/send?phone=13236732534&text=Hola!%20tengo%20una%20duda`}
      >
        <ion-icon name="logo-whatsapp"></ion-icon>
        <h5>Soporte</h5>
      </Link>
    </div>
  );
};

export default Soporte;
