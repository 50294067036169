import { getAllProducts } from "./actions/getAllProducts";
import { getAllCategories } from "./actions/getAllCategories";
import { getAllCommerces } from "./actions/getAllCommerces";
import { getAllSubCategories } from "./actions/getAllSubCategories";
import { postProductsToEditAction } from "./actions/postProductsToEditAction";
import { deleteProductFromEdit } from "./actions/deleteProductFedit";
import { clearEditProducts } from "./actions/clearEditProducts";
import { getAllUsersAction } from "./actions/getAllUsers";
import { getPaginatedProducts } from "./actions/paginatedProducts";
import { clearAllProductsToEdit } from "./actions/clearAllProducts";
import { getFilteredProducts } from "./actions/getFilteredProducts";
import { setLoading } from "./actions/setLoading";
import { setFiltersAction } from "./actions/setFiltersActions";
import { setSelectionsAction } from "./actions/setSelectionsAction";
import { setFilteredLoad } from "./actions/setFilteredLoad";

export const getAllProduct = getAllProducts;
export const getCategories = getAllCategories;
export const getCommerces = getAllCommerces;
export const getSubCategories = getAllSubCategories;
export const postProductsToEdit = postProductsToEditAction;
export const deleteProductEdit = deleteProductFromEdit;
export const clearProducts = clearEditProducts;
export const getAllUsers = getAllUsersAction;
export const getPaginatedProduct = getPaginatedProducts;
export const clearAllProducts = clearAllProductsToEdit;
export const filteredProducts = getFilteredProducts;
export const setLoad = setLoading;
export const setFiltersAct = setFiltersAction;
export const setSelections = setSelectionsAction;
export const setFilteredLoading = setFilteredLoad;
