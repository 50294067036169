import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import styles from "./CrearComercio.module.css";
import { getCommerces } from "../../../redux/actions";
axios.defaults.withCredentials = true;

const CrearComercio = () => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [editingCommerceId, setEditingCommerceId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    name: "",
    state: "",
    url: "",
  });

  const dispatch = useDispatch();
  const commerces = useSelector((state) => state.commerces);

  const handleCreateCommerce = async () => {
    try {
      const response = await axios.post(
        "https://api.devfrikipolis.com/commerces/create",
        {
          name,
          url,
        }
      );

      if (response.data.success) {
        alert("Comercio creado correctamente");
        setName("");
        setUrl("");
        dispatch(getCommerces());
      } else {
        alert("No se pudo crear el comercio");
      }
    } catch (error) {
      console.error(error);
      alert("Error al crear el comercio");
    }
  };

  const handleEditCommerce = async (id) => {
    try {
      const response = await axios.patch(
        `https://api.devfrikipolis.com/commerces/update/${id}`,
        {
          name: editFormData.name,
          state: editFormData.state,
          url: editFormData.url,
        }
      );

      if (response.data.success) {
        alert("Comercio editado correctamente");
        setEditingCommerceId(null);
        dispatch(getCommerces());
      } else {
        alert("No se pudo editar el comercio");
      }
    } catch (error) {
      console.error(error);
      alert("Error al editar el comercio");
    }
  };

  const handleEditFormChange = (e) => {
    setEditFormData({
      ...editFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditClick = (commerce) => {
    setEditingCommerceId(commerce.id);
    setEditFormData({
      name: commerce.name,
      state: commerce.state.toString(),
      url: commerce.url,
    });
  };

  const handleCancelEdit = () => {
    setEditingCommerceId(null);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <h1>Crear Comercio</h1>
        <input
          className={styles.inputCategoria}
          type="text"
          placeholder="Nombre del comercio"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className={styles.inputCategoria}
          type="text"
          placeholder="URL del comercio"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <button className={styles.buttonSubmit} onClick={handleCreateCommerce}>
          Crear Comercio
        </button>
      </div>
      <div className={styles.comerciosActivosContainer}>
        <h2>Comercios Existentes :</h2>
        {commerces.map((commerce) => (
          <div key={commerce.id} className={styles.comercioActivo}>
            {editingCommerceId === commerce.id ? (
              <div className={styles.optionsToEdit}>
                <input
                  type="text"
                  name="name"
                  value={editFormData.name}
                  onChange={handleEditFormChange}
                />
                <label>Estado</label>
                <select
                  name="state"
                  defaultValue={editFormData.state}
                  onChange={handleEditFormChange}
                >
                  <option value="true">Activo</option>
                  <option value="false">Inactivo</option>
                </select>
                <input
                  type="text"
                  name="url"
                  value={editFormData.url}
                  onChange={handleEditFormChange}
                />
                <button onClick={() => handleEditCommerce(commerce.id)}>
                  Guardar
                </button>
                <button
                  className={styles.buttonSubmit}
                  onClick={handleCancelEdit}
                >
                  Cancelar
                </button>
              </div>
            ) : (
              <div className={styles.divCommerceAndEdit}>
                <h3>{commerce.name}</h3>
                <button
                  className={styles.buttonSubmit}
                  onClick={() => handleEditClick(commerce)}
                >
                  Editar
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CrearComercio;
